<template>
  <div class="template-default d-flex flex-column flex-lg-row">
    <aside
      class="py-4 d-flex d-lg-none flex-column"
      :class="{ showing: showingMenu }"
    >
      <div class="d-flex flex-column mb-4">
        <button class="d-lg-none btn btn-menu p-0" @click="showingMenu = false">
          <i class="icon-close"></i>
        </button>

        <button @click="goHome" class="link-logo btn link-control">
          <img
            width="115"
            height="61.8"
            src="@/assets/images/logo.png"
            alt="Logo Elite Digital"
          />
        </button>

        <button
          @click="goHome"
          class="link-control btn"
          :class="active_tab == 'home' ? 'active' : ''"
        >
          Visão geral
        </button>
        <button
          @click="goProfessionals"
          class="link-control btn"
          :class="active_tab == 'professionals' ? 'active' : ''"
        >
          Ver profissionais
        </button>
        <button
          @click="goProviders"
          class="link-control btn"
          :class="active_tab == 'providers' ? 'active' : ''"
        >
          Ver fornecedores
        </button>
        <button
          @click="checkOpportunity"
          class="link-control btn"
          :class="active_tab == 'opportunities' ? 'active' : ''"
        >
          Oportunidades
        </button>
        <button
          @click="checkProjects"
          :class="active_tab == 'new-opportunity' ? 'active' : ''"
          class="link-control btn"
        >
          Minhas oportunidades
        </button>
        <router-link
          v-if="$user.user"
          :to="{ name: 'my-professional-profile' }"
          @click.native="showingMenu = false"
          class="pl-4 text-left btn btn-sm text-light btn-block btn-link-side"
        >
          <i class="mr-2 icon-jobs" /> Perfil profissional
        </router-link>
        <router-link
          v-if="$user.user"
          :to="{ name: 'my-account' }"
          class="pl-4 text-left btn btn-sm text-light btn-block btn-link-side"
        >
          <i class="mr-2 icon-eva_person-outline" /> Minha conta
        </router-link>
        <button
          v-if="$user.user"
          @click="showLogout"
          class="pl-4 text-left btn btn-sm text-light btn-block btn-link-side"
        >
          <i class="mr-2 icon-gridicons_sign-out" /> Sair
        </button>

        <router-link v-else class="link-control btn" :to="{ name: 'login' }">
          Login
        </router-link>
      </div>
    </aside>

    <main class="flex-fill">
      <custom-header @show-menu="v => (showingMenu = v)"></custom-header>
      <div class="mt-5 pt-5">
        <slot @cancelPadding="cancelPadding" />
        <button
          id="floating-btn"
          class="btn btn-floating fade-in-animation"
          @click="openContact"
        >
          <i class="icon-whatsapp"></i>
        </button>
      </div>
      <custom-footer />
    </main>
    <confirmation
      iconType="warning"
      title="Deseja mesmo sair?"
      v-model="showLogoutConfirmation"
      confirmButtonClass="btn btn-danger"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      text="Para sair da plataforma clique em confirmar"
      @confirm="logout"
      @cancel="showLogoutConfirmation = false"
    />

    <!-- <confirmation
      iconType="map"
      title="Atualização no portal"
      v-model="showMessageModal"
      confirmButtonClass="btn btn-primary"
      cancelButtonText="Fechar"
      confirmButtonText="Ir para perfil"
      text=""
      @confirm="editProfile"
      @cancel="showMessageModal = false"
    >
      <h4 class="text-center text-dark-secondary px-4 mt-n5 mb-3">
        Agora os certificados poderão ser encontrados pela cidade e estado!
      </h4>
      <p class="text-center px-4 pt-0 pb-3 mb-4 text-dark-secondary text">
        Atualize o seu perfil no menu. <br />
        Acesse “<strong>Editar perfil</strong> → Em
        <strong>contato</strong> informe seu CEP, Cidade e Estado”
      </p>
    </confirmation> -->

    <div
      v-if="showingMenu"
      class="d-lg-none backdrop"
      @click="showingMenu = false"
    ></div>
    <responsive-modal v-model="showLogin" :hideHeader="false" centered>
      <certification-modal
        title="TER ACESSO A TODOS OS BENEFÍCIOS"
        text1="Faça login ou cadastre-se no Bora Conecta para"
        text2="da plataforma."
        buttonCopy="Entrar"
        buttonLink="login"
        type="login"
      />
    </responsive-modal>
    <responsive-modal v-model="showCertification" :hideHeader="false" centered>
      <certification-modal
        title="Formação em Projetos e Obras BORAnaOBRA"
        text1="Somente certificados na"
        text2="podem ver oportunidades."
        buttonCopy="Quero ser certificado"
        buttonLink="https://google.com"
        type="certificate"
      />
    </responsive-modal>
  </div>
</template>
<script>
import CertificationModal from "@/components/home/CertificationModal.vue";
export default {
  components: {
    CertificationModal
  },
  data() {
    const active_tab = this.$route.name;
    return {
      showingMenu: false,
      canceledPadding: false,
      showLogoutConfirmation: false,
      showMessageModal: false,
      scrollListener: null,
      showLogin: false,
      showCertification: false,
      active_tab
    };
  },
  created() {
    //must be removed this if when backend open this endpoint
    if (this.$user.user) {
      this.$store.dispatch("professional/getCategories");
    }
  },
  mounted() {
    if (this.$user.user) {
      this.$store.dispatch("user/get");
    }
    this.registerScroll();
    if (this.$user.profile && !this.$user.profile.city) {
      this.showMessageModal = true;
      let params = new URLSearchParams(window.location.search);
      params.delete("message");
      let newParams = params.toString();
      let newPath =
        newParams && newParams.length
          ? `${window.location.pathname}?${newParams}`
          : `${window.location.pathname}`;
      window.history.pushState({ path: newPath }, "", newPath);
    }
  },
  unmounted() {
    this.removeScroll();
  },
  methods: {
    editProfile() {
      this.$router.push({
        name: "my-professional-profile"
      });
    },
    registerScroll() {
      const btn = document.getElementById("floating-btn");

      if (btn) {
        this.scrollListener = document.addEventListener("scroll", () => {
          const btn = document.getElementById("floating-btn");
          if (
            window.innerHeight + window.scrollY >=
            document.body.scrollHeight
          ) {
            btn.style.bottom = `25%`;
          } else {
            btn.style.bottom = `3%`;
          }
        });
      }
    },
    removeScroll() {
      document.removeEventListener("scroll", this.scrollListener);
    },
    openContact() {
      window.open(
        "https://api.whatsapp.com/send?phone=556196088530&text=Olá, estou utilizando o Bora Conecta e gostaria de ajuda.",
        "_blank"
      );
    },
    cancelPadding(v) {
      this.canceledPadding = v;
    },
    showLogout() {
      this.showingMenu = false;
      this.showLogoutConfirmation = true;
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    checkProjects() {
      this.doEmit();
      if (this.$user.user) {
        this.$router.push({ name: "new-opportunity" });
      } else {
        this.showLogin = true;
      }
    },
    checkOpportunity() {
      this.doEmit();
      if (this.$user.user) {
        if (this.$user.profile.emblem) {
          this.$router.push({ name: "opportunities" });
        } else {
          this.showCertification = true;
        }
      } else {
        this.showLogin = true;
      }
    },
    goHome() {
      this.doEmit();
      this.$router.push({ name: "home" });
    },
    goProviders() {
      this.doEmit();
      this.$router.push({ name: "providers" });
    },
    doEmit() {
      this.showingMenu = false;
    },
    goProfessionals() {
      this.doEmit();
      this.$router.push({ name: "professionals" });
    }
  },
  watch: {
    "$route.name"(name) {
      this.active_tab = name;
    }
  }
};
</script>

<style lang="scss" scoped>
.template-default {
  min-height: 100vh;
  overflow: hidden;
  background: #000000;

  .btn-floating {
    position: fixed;
    background: green;
    border-radius: 50%;
    height: 55px;
    width: 55px;
    right: 3%;
    bottom: 2%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
      font-size: 34px;
    }
  }

  .btn-menu {
    font-size: 24px;
    position: absolute;
    left: 2%;
    top: 2%;
  }

  aside {
    background: #000000;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    max-width: 250px;
    min-width: 250px;
    z-index: 10000000000;

    @media screen and (max-width: 991px) {
      transform: translateX(-100%);
      position: fixed;
      top: 0;
      bottom: 0;
      box-shadow: 4px 0 16px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s;

      &.showing {
        transform: translateX(0);
      }
    }
  }

  .backdrop {
    background: rgba(33, 38, 49, 0.25);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .btn-link-side {
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
    }
  }
  .active {
    background: rgba(1, 65, 108, 0.05);
    border-bottom: 3px solid #ffcc00;
    color: #ffcc00 !important;
    border-radius: 0px;
  }
}
</style>
